import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/bootsite/tippyjs.bootcss.com/website/src/components/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`What syntax theme is used on this website?`}</h3>
    <p>{`It's a theme I made called
`}<a parentName="p" {...{
        "href": "https://github.com/atomiks/moonlight-vscode-theme"
      }}>{`Moonlight`}</a>{`!`}</p>
    <h3>{`Nothing is working`}</h3>
    <p>{`Make sure Tippy's scripts are placed `}<em parentName="p">{`before`}</em>{` your own scripts, at the very
bottom of the page, like so:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token doctype"
          }}>{`<!DOCTYPE html>`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`head`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`My page`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`head`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`body`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Text`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- Very end of the body -->`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://unpkg.com/popper.js@1`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://unpkg.com/tippy.js@5`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}><span parentName="span" {...{
              "className": "token language-javascript"
            }}>{`
      `}<span parentName="span" {...{
                "className": "token function"
              }}>{`tippy`}</span><span parentName="span" {...{
                "className": "token block"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`'`}</span>{`button`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`'`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token block"
              }}>{`{`}</span>{`content`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`'`}</span>{`tooltip`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`'`}</span></span><span parentName="span" {...{
                "className": "token block"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token block"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
    `}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`body`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3>{`My tooltip appears cut off or is not showing at all`}</h3>
    <p>{`When using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`interactive: true`}</code>{`, the tippy may be invisible or appear cut off if
your reference element is in a container with:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`position`}</code>{` (e.g. fixed, absolute, sticky)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`overflow: hidden`}</code></li>
    </ul>
    <p>{`To fix add the following prop (recommended):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`targets`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ...`}</span>{`
  popperOptions`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
    positionFixed`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`
  `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Or, if the above causes issues:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`targets`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ...`}</span>{`
  appendTo`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token dom variable"
          }}>{`document`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`body`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`⚠️ For the latter, you need to be employing focus management for accessibility.`}</p>
    <h3>{`I'm getting Uncaught ReferenceError: process is not defined`}</h3>
    <p>{`If you're using the ESM or CJS versions and importing like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` tippy `}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// or`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`tippy`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token module keyword"
          }}>{`default`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Tippy uses a special expression to distinguish a development and production
environment. For you, the developer, there are lots of warnings and error
messages to help your development experience. For your end users, all of this
needs to get stripped out because it reduces performance and increases bundle
size.`}</p>
    <p>{`Tools like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`create-react-app`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Parcel`}</code>{` bundler handle this automatically.`}</p>
    <h4>{`Browserify/Grunt/Gulp`}</h4>
    <p><a parentName="p" {...{
        "href": "https://vuejs.org/v2/guide/deployment.html#With-Build-Tools"
      }}>{`View the following link`}</a>{`.`}</p>
    <h4>{`Rollup`}</h4>
    <p>{`Install the
`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/rollup-plugin-replace"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`replace plugin`}</code></a>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` replace `}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`rollup-plugin-replace`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`default`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ...`}</span>{`
  plugins`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Production config`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`replace`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`process.env.NODE_ENV`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSON`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`stringify`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`production`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// OR development config`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`replace`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`process.env.NODE_ENV`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSON`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`stringify`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`development`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// You can also use process.env.NODE_ENV and set the env variables when`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// running the rollup command to merge the above into one call`}</span>{`
  `}<span parentName="code" {...{
            "className": "token block"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`How do I use the animations or themes stylesheets with the CDN?`}</h3>
    <p>{`For brevity, this documentation uses `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`import`}</code>{` syntax and assumes a module
bundler environment. If you're using the CDN version, then you'll be using
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<link>`}</code>{` tags to import separate CSS stylesheets.`}</p>
    <p><a parentName="p" {...{
        "href": "/getting-started/#node-vs-browser"
      }}>{`See the example here`}</a>{`.`}</p>
    <h3>{`What are those "iife", "esm" and "cjs" initialisms?`}</h3>
    <p>{`They represent JavaScript formats. You don't need to know how they work, just
which one you're using:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Initialism`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Full Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Tree-Shaking?`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`iife`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Immediately Invoked Function Expression`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used via script tags in the browser (CDN)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`esm`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`ECMAScript Module`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used via `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`import`}</code>{` syntax in module bundlers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`cjs`}</code></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`CommonJS Module`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used via `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`require()`}</code>{` syntax in module bundlers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only in `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`Parcel`}</code>{` currently`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tippy.js`}</code>{` via the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`esm`}</code>{` format is the most recommended way, since you get
the benefits of tree-shaking in all bundlers.`}</p>
    <h3>{`What global side effects are there?`}</h3>
    <p>{`Tippy has some environment side effects to make it work better across different
devices.`}</p>
    <h4>{`iOS's inability to employ "click outside" behavior`}</h4>
    <p>{`Tippy adds this class to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<body>`}</code>{` for iOS when there are any instances
showing:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token class"
            }}>{`.tippy-iOS`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`cursor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pointer `}<span parentName="code" {...{
            "className": "token important"
          }}>{`!important`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`-webkit-tap-highlight-color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` transparent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This allows tooltips to hide when the user taps off a reference element. This
has a side effect of allowing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mouse`}</code>{` related events to fire on "unclickable"
elements on iOS, which is normally not possible. Android browsers on the other
hand allow this, so it effectively makes iOS & Android browsers behave the same.`}</p>
    <h4>{`Document and window listeners`}</h4>
    <p>{`There are global listeners to determine the user's current input type (e.g.
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`touch`}</code>{`), and other UX helpers.`}</p>
    <h3>{`I can't click things inside the tooltip`}</h3>
    <p>{`To enable interactivity, set the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`interactive`}</code>{` prop to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{`.`}</p>
    <h3>{`My tooltip is hiding instantly after showing`}</h3>
    <p>{`If you're using a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`focus`}</code>{` trigger, for example on an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<input>`}</code>{`, make sure you
also set `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hideOnClick: false`}</code>{`.`}</p>
    <h3>{`Changing data-tippy-`}{`*`}{` attributes does not update the tooltip`}</h3>
    <p>{`Updating the data-tippy-`}{`*`}{` attribute on an element will currently not update the
tooltip. You must use the `}<a parentName="p" {...{
        "href": "/methods/#setprops"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`setProps()`}</code>{` method`}</a>{` on a Tippy
instance.`}</p>
    <p>{`For example, let's say you want to update the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`theme`}</code>{` for tooltips when changing
between dark and light mode:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`instance`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`element`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`theme`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`custom-dark`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// When clicking the theme toggle button, you can do this:`}</span>{`
`}<span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`setProps`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`theme`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`custom-light`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`It's also possible to attach a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`MutationObserver`}</code>{` to the reference elements and
observe mutations to attributes if need be, then call `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.setProps()`}</code>{` with the new
values.`}</p>
    <h3>{`Can I use the `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`title`}</code>{` attribute?`}</h3>
    <p>{`Yes. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`content`}</code>{` prop can be a function that receives the reference element as
an argument and returns a string or element.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`button`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`content`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`reference`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`title`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token object"
          }}>{`reference`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`getAttribute`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`title`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token object"
          }}>{`reference`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`removeAttribute`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`title`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token control keyword"
          }}>{`return`}</span>{` title`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`title`}</code>{` attribute should be removed once you have its content so the
browser's default tooltip isn't displayed along with the tippy.`}</p>
    <h4>{`Plugin`}</h4>
    <p>{`You can create a plugin for this to generalize the behavior:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`titleAttribute`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`titleAttribute`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  defaultValue`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fn`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token control keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token function"
          }}>{`onCreate`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token control keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`props`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`titleAttribute`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token control keyword"
          }}>{`return`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`title`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`reference`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`getAttribute`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`title`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

        `}<span parentName="code" {...{
            "className": "token control keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`title`}<span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`setContent`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`title`}<span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
          `}<span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`reference`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`removeAttribute`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`title`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Note that the plugin does not take into account dynamic titles. Be cautious of
why you might need this in the first place.`}</p>
    <h3>{`What's the difference between an addon and a plugin?`}</h3>
    <p>{`An `}<strong parentName="p">{`addon`}</strong>{` is an external function that calls the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tippy()`}</code>{` constructor
because it's controlling or creating many different tippy instances.`}</p>
    <p>{`A `}<strong parentName="p">{`plugin`}</strong>{` is a plain object that hooks into, and adds functionality, to a
single tippy instance that has already already created.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      